<template>
  <div>
    <p class="mt-4 mb-2 text-2xl font-semibold">Handouts</p>
    <Button
      sml
      newTab
      leading="flaticon-pdf-1"
      href="/RMR FSA Enrollment Guide.pdf"
    >
      FSA Enrollment Guide
    </Button>
    <p class="mt-8 mb-2 text-2xl font-semibold">Videos</p>
    <iframe
      src="https://fast.wistia.net/embed/iframe/jn0h9tael7?seo=false&playerColor=096843"
      title="Wistia video player"
      allowtransparency="true"
      frameborder="0"
      scrolling="no"
      class="p-0 m-0 border border-solid rounded wistia_embed border-neutral"
      name="wistia_embed"
      allowfullscreen
      mozallowfullscreen
      webkitallowfullscreen
      oallowfullscreen
      msallowfullscreen
    ></iframe>
    <p class="p-2 m-0 mb-4">Introduction to Flexible Spending Accounts</p>
    <iframe
      src="https://fast.wistia.net/embed/iframe/97bsjir1cp?seo=false&playerColor=096843"
      title="Wistia video player"
      allowtransparency="true"
      frameborder="0"
      scrolling="no"
      class="p-0 m-0 border border-solid rounded wistia_embed border-neutral"
      name="wistia_embed"
      allowfullscreen
      mozallowfullscreen
      webkitallowfullscreen
      oallowfullscreen
      msallowfullscreen
    ></iframe>
    <p class="p-2 m-0 mb-4">Introduction to Limited Purpose FSAs</p>
  </div>
</template>

<script>
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button
  }
};
</script>

<style scoped>
.icon {
  margin-right: 0.25rem;
}
</style>
